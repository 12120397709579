export const serif = "'National Book', Arial, Helvetica, sans-serif";
export const sansSerif = "'Chronicle Light', Adobe Garamond Pro, Helvetica, sans-serif";
export const sansSerifSemiBold = "'Chronicle SemiBold', Adobe Garamond Pro, Helvetica, sans-serif";

const body = `normal 20px/1.5 ${serif}`;
const menu = `normal 22px/1.5 ${sansSerif}`;
const titleH1 = `normal 44px/1.2 ${sansSerif}`;
const titleH2 = `normal 28px/1.2 ${sansSerif}`;
const titleH3 = `normal 22px/1.4 ${sansSerif}`;

export const fonts = {
  sizes: {
    body: '20px',
    title: '14px',
    small: '12px',
  },
  roboto: "'Roboto', Arial, Helvetica, sans-serif",
  body,
  menu,
  titleH1,
  titleH2,
  titleH3,
};
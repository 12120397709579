import React from 'react';

const FaqQuestionContext = React.createContext({
  questionId: '',
  setQuestionId: (newQuestionId: string) => {
    console.log(newQuestionId);
  },
});

export default FaqQuestionContext;

export const breakpoints = {
  xsmall: 480,
  small: 600,
  medium: 768,
  large: 1024,
  xlarge: 1200,
  xxlarge: 1920,
};

export const mediaQueries = {
  xsmall: `@media screen and (min-width: ${breakpoints.xsmall}px)`,
  small: `@media screen and (min-width: ${breakpoints.small}px)`,
  medium: `@media screen and (min-width: ${breakpoints.medium}px)`,
  large: `@media screen and (min-width: ${breakpoints.large}px)`,
  xlarge: `@media screen and (min-width: ${breakpoints.xlarge}px)`,
  xxlarge: `@media screen and (min-width: ${breakpoints.xxlarge}px)`,
};

import React, { useState } from 'react';
import { AppProps as DefaultAppProps } from 'next/app';
import { AppContextProvider } from '@src/context/AppContext';
import { ThemeProvider } from '@emotion/react';
import { theme } from '@src/theme';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { GlobalStyles } from '@src/theme/GlobalStyles';
import 'src/styles/base.css';
import 'src/styles/utils.css';
import FaqQuestionContext from '@src/context/FaqQuestionContext';
import { isDevEnv } from '@src/utils/isDevEnv';

type AppProps = DefaultAppProps & {
  deviceType?: string;
};

function App({ Component, pageProps, deviceType }: AppProps) {
  const [questionId, setQuestionId] = useState('');
  return (
    <AppContextProvider deviceType={deviceType}>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href={`/assets/favicon.png`}
        />
      </Head>
      <DefaultSeo
        openGraph={{
          type: 'website',
          locale: 'en-gb',
          site_name: 'Niels Stensen Fellowship',
        }}
        dangerouslySetAllPagesToNoIndex={isDevEnv()}
      />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <FaqQuestionContext.Provider
          value={{
            questionId: questionId,
            setQuestionId: setQuestionId,
          }}
        >
          <Component {...pageProps} />
        </FaqQuestionContext.Provider>
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default App;

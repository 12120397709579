import type { FC } from 'react';
import { createContext } from 'react';

type AppContextType = {
  deviceType?: string;
};
export const AppContext = createContext<AppContextType>({});

export type AppProviderProps = AppContextType;

export const AppContextProvider: FC<AppProviderProps> = ({
  children,
  deviceType,
}) => {
  return (
    <AppContext.Provider value={{ deviceType }}>{children}</AppContext.Provider>
  );
};

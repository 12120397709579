import { Global } from '@emotion/react';
import { theme } from '.';

export const GlobalStyles = () => {
  // const { spacings, colors } = useTheme();

  return (
    <Global
      styles={{
        body: {
          margin: 0,
          padding: 0,
          font: theme.fonts.body,
          color: theme.colors.black,
        },

        '*': {
          boxSizing: 'border-box',
        },

        [`h1,h2,h3,h4,h5`]: {
          padding: 0,
          margin: 0,
          fontFamily: '"Chronicle Light", arial',
          marginBottom: 30,
          fontWeight: 400,
          lineHeight: 1.4,
        },

        h1: {
          fontSize: 44,
          lineHeight: 1.2,
          marginBottom: 40,
        },

        h2: {
          fontSize: 28,
        },

        h3: {
          fontSize: 22,
        },

        p: {
          margin: 0,
          marginBottom: theme.spacings.l,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',

          a: {
            color: 'inherit',
          },
        },

        strong: {
          fontFamily: "'National Medium',Arial,Helvetica,sans-serif",
        },

        [`ul,ol`]: {
          margin: 0,
          padding: 0,
          marginLeft: theme.spacings.m,
          marginBottom: theme.spacings.xl,
        },

        img: {
          width: '100%',
          verticalAlign: 'middle',
        },

        a: {
          textUnderlineOffset: '3px',
          textDecorationThickness: '1px',
        },
      }}
    />
  );
};

import { fonts } from './fonts';
import { colors } from './colors';
import { Theme } from '@emotion/react';
import { mediaQueries } from './mediaQueries';
import { spacings } from './spacings';
import { cards } from './cards';

const transitions = {
  fast: 'all 100ms ease',
  medium: 'all 300ms ease',
};

const width = {
  s: '420px',
  m: '900px',
  l: '1120px',
};

export const theme: Theme = {
  spacings,
  fonts,
  colors,
  mediaQueries,
  transitions,
  width,
  cards
};